<template>
  <div class="details-section">
    <Loader v-if="isLoading" loading full />
    <Loader
      v-if="isFetching && !order"
      loading
      :title="$t('Fetching billing details...')"
    />
    <form v-else-if="order" @submit.prevent="onClickPrimary">
      <div v-if="errors._message" class="alert alert-danger">
        {{ errors._message }}
      </div>

      <div v-if="showStripe">
        <h4 class="section-title">{{ $t('Card Payment') }}</h4>
        <StripeElementCard
            ref="card"
            :pk="order.stripepublickey"
            :elements-options="{
              locale: 'en-US',
            }"
            :hide-postal-code="true"
            :value="stripeValue"
            class="inline-card"
            @element-ready="onCardReady"
            @token="onCardTokenCreated"
            @loading="onLoading"
        />
      </div>
      <div v-else-if="order.billing_entity == 'US'">
        <p>IMPORTANT NOTE: Our payment information has changed. Please review the updated payment information at <a href="https://www.filecloud.com/payment-instructions">https://www.filecloud.com/payment-instructions</a>.</p>
      </div>
      <div v-else>
        <p>IMPORTANT NOTE: You will find our bank details on your invoice. Please provide a PO document or Order Form to <a href="mailto:sales@filecloud.com">sales@filecloud.com</a> so we can raise an invoice.</p>
      </div>

      <div class="divider"></div>

      <div class="subtotal-section mt-4">
        <div class="total-description">
          <h4>{{ $t('Invoice Number') }}</h4>
        </div>
        <strong class="pricing">{{ order.order_number }}</strong>
      </div>
      <div class="subtotal-section">
        <div class="total-description">
          <h4>{{ $t('Order Sub Total') }}</h4>
        </div>
        <div class="pricing">
          {{ formatInlinePrice(order.order_item_subtotal) }}
        </div>
      </div>
      <div class="subtotal-section">
        <div class="total-description">
          <h4>{{ $t('Sales Tax') }}</h4>
        </div>
        <div class="pricing">
          {{ formatInlinePrice(order.order_tax_total) }}
        </div>
      </div>
      <div v-if="order.payment_received_total > 0" class="subtotal-section">
        <div class="total-description">
          <h4>{{ $t('Total Paid') }}</h4>
        </div>
        <div class="pricing">
          - {{ formatInlinePrice(order.payment_received_total) }}
        </div>
      </div>
      <div class="total-section">
        <div class="total-description">
          <h4>{{ $t('Total Due') }}</h4>
          <p>{{ $t('All prices are in U.S. Dollars') }}</p>
        </div>
        <div class="pricing">
          <div
            class="price"
            v-html="
              formatInlinePrice(
                order.order_total_due - order.payment_received_total
              )
            "
          ></div>
        </div>
      </div>

      <div class="disclaimer-section">
        <div id="disclaimer-sales-tax-exempt">
          <p v-if="order.billing_entity == 'US'">If you're sales tax exempt, please send us your exempt form through sales@filecloud.com so we can update our records.</p>
          <p v-else-if="order.billing_entity == 'IE'">If you're VAT exempt, please send us your VAT number through sales@filecloud.com so we can update our records.</p>
          <p v-else>5% VAT applies to the seven emirates within the United Arab Emirates (UAE).</p>
        </div>
        <div v-if="showStripe" id="disclaimer-stripe">
          By proceeding you accept FileCloud's
          <a href="https://www.filecloud.com/tos/" target="_blank">Terms of Service</a>
          <br />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import Loader from 'common/components/Loader';
import { formatInlinePrice, formatPrice } from '@/utils/checkout';

export default {
  name: 'Payment',
  components: {
    Loader,
    StripeElementCard,
  },
  props: {
    currentProps: {
      type: Object,
      default: () => {},
    },
    payOnly: {
      type: Boolean,
      default: false,
    },
    quoteCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {},
      errors: {},
      isStripeLoading: true,
    };
  },
  computed: {
    stripeValue() {
      if (!this.order) return '{}';
      if (typeof this.order.customer_zip === 'number') {
        return `{ postalCode: '${this.order.customer_zip}' }`;
      } else if (
        this.order.customer_zip.match &&
        this.order.customer_zip.match(/\d+/g)
      ) {
        return `{ postalCode: '${this.order.customer_zip
          .match(/\d+/g)
          .join('')}' }`;
      }
      return '{}';
    },
    showStripe() {
      let billingEntity = this.order.billing_entity;
      let orderTotal = this.order.order_total_due - this.order.payment_received_total;

      if (billingEntity == 'US' && this.user.stripelimitexempt == 1) {
        return true;
      }

      if (billingEntity == 'US' && orderTotal <= 20000)
        return true;
      else if (billingEntity == 'US' && orderTotal > 20000)
        return false;

      if (billingEntity == 'IE' || billingEntity == 'AE')
        return false;
      else
        return true;
    },
    order() {
      return this.$store.state.checkout.singleOrder[
        this.currentProps.orderNumber
      ];
    },
    system() {
      return this.$store.state.core.system;
    },
    user() {
      return this.$store.state.core.user;
    },
    isFetching() {
      return this.$store.state.loading['checkout/getOrder'];
    },
    isLoading() {
      return (
        this.isStripeLoading || this.$store.state.loading['checkout/payOrder']
      );
    },
  },
  async mounted() {
    if (!this.order) {
      await this.$store.dispatch('checkout/getOrder', {
        number: this.currentProps.orderNumber,
        accesscode: this.quoteCode,
      });
    }

    if (this.showStripe) {
      this.$emit('settings', {
        secondary: this.quoteCode ? 'Download Quote' : 'Save Quote',
        primary: 'Pay Now',
      });
    } else {
      this.$emit('settings', {
        secondary: this.quoteCode ? 'Download Quote' : 'Save Quote',
      });
      this.isStripeLoading = false;
    }
  },
  methods: {
    async onClickPrimary() {
      this.$refs.card.submit();
    },

    // submit
    async onCardTokenCreated(card) {
      const response = await this.$store.dispatch('checkout/payOrder', {
        ordernumber: this.order.order_number,
        stripeToken: card.id,
        stripeTokenType: card.type,
        stripeEmail: this.user.email,
        accesscode: this.quoteCode ? this.quoteCode : undefined,
        paymentmode: 1,
      });

      if (!response.ok) {
        this.errors =
          typeof response.error === 'string'
            ? { _message: response.error }
            : response.error;
        return false;
      }

      this.$emit('changeStep', { step: 'paid' });
    },

    async onClickSecondary() {
      // save pdf
      if (this.payOnly) {
        await this.$store.dispatch('checkout/downloadQuote', {
          code: this.quoteCode,
          orderNumber: this.order.order_number,
        });
      } else {
        // save with status 2 = quote
        const updateResponse = await this.$store.dispatch(
          'checkout/updateOrder',
          {
            ordernumber: this.order.order_number,
            orderstatus: 2,
          }
        );

        if (!updateResponse.ok) {
          this.errors =
            typeof updateResponse.error === 'string'
              ? { _message: updateResponse.error }
              : updateResponse.error;
          return false;
        }

        this.$router.push(`/billing/${this.order.order_number}`);
        this.$modal.hide('checkout');
      }
    },

    onCardReady() {
      this.isStripeLoading = false;
      this.$refs.card.focus();
    },

    onLoading(loading) {
      this.isStripeLoading = loading;
    },

    formatInlinePrice,
    formatPrice,
  },
};
</script>

<style scoped>
#disclaimer-sales-tax-exempt p {
  line-height: 1rem;
}
</style>
